
// Fonts
@import url(https://fonts.googleapis.com/css?family=Poppins|Work+Sans:600&display=swap);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Addons
@import "layout";

body {background-image: url('/images/office.png');}
p,a,ul,li,address,div,span {font-family: 'Poppins', sans-serif; font-weight:300;}
h1,h2,h3,h4,h5,h6,strong {font-family: 'Work Sans', sans-serif; font-weight: 600;}
kbd {margin-right:10px;}
.payroll .form-group, .payroll .control-label {margin-bottom: 0px;}

.navbar {min-height: 34px;margin-bottom: 0px;}
.navbar-brand {height: 34px;padding: 0px;}
.navbar-nav>li>a {padding-top: 7px;padding-bottom: 7px;}
.navbar-nav>li.active>a>span.badge {background-color: #FFF; color: $brand-success;}

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {background-color: #f4f0ea}
.navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:hover, .navbar-inverse .navbar-nav > .active > a:focus {background-color: #087D3F;}

.bg-white {background: #fff;}
.bg-gray {background: #c0c0c0;}
.text-subdue {color: #c0c0c0;}
.text-white {color: #fff;}
.text-title-btn {font-size: 11px;}
.text-guide {font-size: 12px; font-weight: 700; color: #959595; text-transform: uppercase; letter-spacing: 1px;}
.text-lighter {font-weight: lighter;}
.text-big {font-size: 1.25em;}
.text-small {font-size: 0.85em; line-height:1.25em;}
.text-finger {cursor: pointer;}
a:hover small.text-danger {color: #a94442;}
.time-box small {font-size: 0.75em;}

.no-top {margin-top: 0px;}
.no-bottom {margin-bottom: 0px;}
.no-top-padding {padding-top: 0px;}
.no-bottom-padding {padding-bottom: 0px;}

.margin-top {margin-top: 12px;}

.form-datepicker {border: 0px; font-size: 21px; font-weight: 300; line-height: 1.4;}

.panel-inverse {border-color: #ddd;}
.panel-inverse>.panel-heading {color: #f5f5f5; background-color: #333; border-color: #ddd;}
.panel-extendable {padding-bottom: 5px;}
.panel-squished {padding: 5px 15px;}
.panel-heading.rehire {background-image: url(../images/rehire.png); background-repeat: no-repeat; background-position: right -1px;}

.bar-green {border-bottom: #339933 5px solid !important;}
.bar-gray {border-bottom: #999999 5px solid !important;}
.bar-yellow {border-bottom: #FFCE00 5px solid !important;}
.bar-red {border-bottom: #990000 5px solid !important;}
.bar-blue {border-bottom: #3299CC 5px solid !important;}

.help-block {font-size:12px;margin-top:0px;margin-bottom:0px;margin-left:3px;}
.help-block.text-danger {color:$brand-danger;}
.hr-pay {border-top-color:lightgray;margin-top:8px;margin-bottom:8px;}
.time-box {padding-left: 0px; padding-right: 15px;}

.btn-inverse {color: #ffffff;background-color: #444444;border-color: #000;} 
.btn-inverse:hover, .btn-inverse:focus, .btn-inverse:active, .btn-inverse.active, .open .dropdown-toggle.btn-inverse {color: #ffffff;background-color: #333333;border-color: #000;} 
.btn-inverse:active, .btn-inverse.active, .open .dropdown-toggle.btn-inverse {background-image: none;} 
.btn-inverse.disabled, .btn-inverse[disabled], fieldset[disabled] .btn-inverse, .btn-inverse.disabled:hover, .btn-inverse[disabled]:hover, fieldset[disabled] .btn-inverse:hover, .btn-inverse.disabled:focus, .btn-inverse[disabled]:focus, fieldset[disabled] .btn-inverse:focus, .btn-inverse.disabled:active, .btn-inverse[disabled]:active, fieldset[disabled] .btn-inverse:active, .btn-inverse.disabled.active, .btn-inverse[disabled].active, fieldset[disabled] .btn-inverse.active {background-color: #444444;border-color: #000;} 
.btn-inverse .badge {color: #444444;background-color: #ffffff;}

.well-admin {padding: 3px;}
.well-admin h6 {border-bottom: 2px solid #ddd;
text-align: center;
font-size: 0.65em;
font-weight: bold;
padding-bottom: 3px;
margin: 0px;
/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f5f5f5+0,ffffff+100 */
background: #f5f5f5; /* Old browsers */
background: -moz-linear-gradient(top, #f5f5f5 0%, #ffffff 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, #f5f5f5 0%,#ffffff 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, #f5f5f5 0%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5f5f5', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */}
.table-admin thead {font-size:0.65em;}
.table-condensed.table-admin > thead > tr > th {padding: 2px;}

.tab-content {padding: 12px 0px;}

.well-square .dl-horizontal {margin-bottom: 0px;}
.well-square .dl-horizontal dt {width: 25%; font-size: 10px;}
.well-square .dl-horizontal dd {margin-left: 30%; font-size: 10px;}

hr {margin-top: 10px; margin-bottom: 10px; border: 0; border-top: 1px solid #ccc;}
.well-portal .form-group {margin-bottom: 3px;}
.well-portal .form-group .help-block {margin-bottom: 0px;}

.badge-primary {background-color: $brand-primary;}
.badge-info {background-color: $brand-info;}
.badge-success {background-color: $brand-success;}
.badge-warning {background-color: $brand-warning;}
.badge-danger {background-color: $brand-danger;}

div.label-hide label {display: none !important;}
div.label-cloak label {color: #fff !important;}
.truncate {
    width: 155px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}

.callout {
    padding: 20px;
    margin: 20px 0;
    border: 1px solid #eee;
    border-left-width: 5px;
    border-radius: 3px;
}

.callout h4 {
    margin-top: 0;
    margin-bottom: 5px;
}

.callout-primary {
    border-left-color: $brand-primary;
}

.callout-info {
    border-left-color: $brand-info;
}

.callout-success {
    border-left-color: $brand-success;
}

.callout-warning {
    border-left-color: $brand-warning;
}

.callout-danger {
    border-left-color: $brand-danger;
}

.callout-primary h4 {
    color: $brand-primary;
}

.callout-info h4 {
    color: $brand-info;
}

.callout-success h4 {
    color: $brand-success;
}

.callout-warning h4 {
    color: $brand-warning;
}

.callout-danger h4 {
    color: $brand-danger;
}

/* BTN INVERSE */
.btn-outline {
  color: inherit;
  background-color: transparent;
  transition: all .5s;
}

.btn-primary.btn-outline:hover, .btn-success.btn-outline:hover, .btn-info.btn-outline:hover, .btn-warning.btn-outline:hover, .btn-danger.btn-outline:hover {
  color: #fff;
}

.btn-primary.btn-outline {
    color: $brand-primary;
}

.btn-info.btn-outline {
    color: $brand-info;
}

.btn-success.btn-outline {
    color: $brand-success;
}

.btn-warning.btn-outline {
    color: $brand-warning;
}

.btn-danger.btn-outline {
    color: $brand-danger;
}

tr[data-href] {
    cursor: pointer;
}

tr[data-href]:hover {
    background-color: lighten($text-color, 55%);
}

@media only print {
    .label-print {
        background-color: black !important;
        color: white !important;
    }
    .bar-green {
        border-bottom-color: black !important;
    }
}

.bg-requested {background: url('/images/pending.png') no-repeat left top;}
.bg-approved {background: url('/images/approved.png') no-repeat left top;}
.bg-rejected {background: url('/images/rejected.png') no-repeat left top;}

@media (max-width: $screen-xs-max) { 
    .navbar-brand .logo { margin: 8px 0px; }
}

.search-box {
    background: inherit;
    border: 0px;
    font-style: italic;
    font-weight: lighter;
}

.search-box:active, .search-box:active {
}

.select-bar-control {
    width: 200px;
    height: 24px;
    padding: 2px 6px;
    font-size: 11px;
    line-height: 1.1;
    color: white;
    background-color: inherit;
    background-image: none;
    border: 1px solid black;
    border-radius: 0;
    margin: -3px;
}

.table-cell { display:table-cell; }

.cell-full { max-width:1px; width:100%; }
.cell-ellipsis { overflow:hidden; white-space:nowrap; text-overflow:ellipsis; }
.cell-noWrap { white-space:nowrap; }