/* GLOBALS */
.no-padding {
  padding: 0 !important;
}
.no-borders {
  border: none !important;
}
.no-margins {
  margin: 0 !important;
}
.no-top-border {
  border-top: 0 !important;
}

/* MARGINS & PADDINGS */
.p-xxs {
  padding: 5px;
}
.p-xs {
  padding: 10px;
}
.p-sm {
  padding: 15px;
}
.p-m {
  padding: 20px;
}
.p-md {
  padding: 25px;
}
.p-lg {
  padding: 30px;
}
.p-xl {
  padding: 40px;
}
.p-w-xs {
  padding: 0 10px;
}
.p-w-sm {
  padding: 0 15px;
}
.p-w-m {
  padding: 0 20px;
}
.p-w-md {
  padding: 0 25px;
}
.p-w-lg {
  padding: 0 30px;
}
.p-w-xl {
  padding: 0 40px;
}
.p-h-xs {
  padding: 10px 0;
}
.p-h-sm {
  padding: 15px 0;
}
.p-h-m {
  padding: 20px 0;
}
.p-h-md {
  padding: 25px 0;
}
.p-h-lg {
  padding: 30px 0;
}
.p-h-xl {
  padding: 40px 0;
}
.m-xxs {
  margin: 2px 4px;
}
.m-xs {
  margin: 5px;
}
.m-sm {
  margin: 10px;
}
.m-md {
  margin: 20px;
}
.m-lg {
  margin: 30px;
}
.m-xl {
  margin: 50px;
}
.m-n {
  margin: 0 !important;
}
.m-l-none {
  margin-left: 0;
}
.m-l-xs {
  margin-left: 5px;
}
.m-l-sm {
  margin-left: 10px;
}
.m-l {
  margin-left: 15px;
}
.m-l-md {
  margin-left: 20px;
}
.m-l-lg {
  margin-left: 30px;
}
.m-l-xl {
  margin-left: 40px;
}
.m-l-n-xxs {
  margin-left: -1px;
}
.m-l-n-xs {
  margin-left: -5px;
}
.m-l-n-sm {
  margin-left: -10px;
}
.m-l-n {
  margin-left: -15px;
}
.m-l-n-md {
  margin-left: -20px;
}
.m-l-n-lg {
  margin-left: -30px;
}
.m-l-n-xl {
  margin-left: -40px;
}
.m-t-none {
  margin-top: 0;
}
.m-t-xxs {
  margin-top: 1px;
}
.m-t-xs {
  margin-top: 5px;
}
.m-t-sm {
  margin-top: 10px;
}
.m-t {
  margin-top: 15px;
}
.m-t-md {
  margin-top: 20px;
}
.m-t-lg {
  margin-top: 30px;
}
.m-t-xl {
  margin-top: 40px;
}
.m-t-n-xxs {
  margin-top: -1px;
}
.m-t-n-xs {
  margin-top: -5px;
}
.m-t-n-sm {
  margin-top: -10px;
}
.m-t-n {
  margin-top: -15px;
}
.m-t-n-md {
  margin-top: -20px;
}
.m-t-n-lg {
  margin-top: -30px;
}
.m-t-n-xl {
  margin-top: -40px;
}
.m-r-none {
  margin-right: 0;
}
.m-r-xxs {
  margin-right: 1px;
}
.m-r-xs {
  margin-right: 5px;
}
.m-r-sm {
  margin-right: 10px;
}
.m-r {
  margin-right: 15px;
}
.m-r-md {
  margin-right: 20px;
}
.m-r-lg {
  margin-right: 30px;
}
.m-r-xl {
  margin-right: 40px;
}
.m-r-n-xxs {
  margin-right: -1px;
}
.m-r-n-xs {
  margin-right: -5px;
}
.m-r-n-sm {
  margin-right: -10px;
}
.m-r-n {
  margin-right: -15px;
}
.m-r-n-md {
  margin-right: -20px;
}
.m-r-n-lg {
  margin-right: -30px;
}
.m-r-n-xl {
  margin-right: -40px;
}
.m-b-none {
  margin-bottom: 0;
}
.m-b-xxs {
  margin-bottom: 1px;
}
.m-b-xs {
  margin-bottom: 5px;
}
.m-b-sm {
  margin-bottom: 10px;
}
.m-b {
  margin-bottom: 15px;
}
.m-b-md {
  margin-bottom: 20px;
}
.m-b-lg {
  margin-bottom: 30px;
}
.m-b-xl {
  margin-bottom: 40px;
}
.m-b-n-xxs {
  margin-bottom: -1px;
}
.m-b-n-xs {
  margin-bottom: -5px;
}
.m-b-n-sm {
  margin-bottom: -10px;
}
.m-b-n {
  margin-bottom: -15px;
}
.m-b-n-md {
  margin-bottom: -20px;
}
.m-b-n-lg {
  margin-bottom: -30px;
}
.m-b-n-xl {
  margin-bottom: -40px;
}
.space-15 {
  margin: 15px 0;
}
.space-20 {
  margin: 20px 0;
}
.space-25 {
  margin: 25px 0;
}
.space-30 {
  margin: 30px 0;
}
.img-sm {
  width: 32px;
  height: 32px;
}
.img-md {
  width: 64px;
  height: 64px;
}
.img-lg {
  width: 96px;
  height: 96px;
}
.b-r-xs {
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}
.b-r-sm {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.b-r-md {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.b-r-lg {
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
}
.b-r-xl {
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
}
body.modal-open {
  padding-right: inherit !important;
}
body.modal-open .wrapper-content.animated {
  -webkit-animation: none;
}
body.modal-open .animated {
  animation-fill-mode: initial;
  z-index: inherit;
}